import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="component-wrapper">
      <h1>Nothing to see here.</h1>
      <p>You route you hit doesn&#39;t exist. Are you sure this is where you wanted to go?</p>
    </div>
  </Layout>
)

export default NotFoundPage
